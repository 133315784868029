
import { mapGetters } from "vuex"

export default {
  props: {
    slide: Object
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    })
  }

  // mounted() {
  // 	const slideContentBlock = this.$refs.slideContentBlock
  // 	const bottomBorder = document.getElementsByClassName('bottomBorder')[0]
  // 	window.addEventListener('scroll', (e) => {

  // 		if (window.scrollY > slideContentBlock.getBoundingClientRect().height) {
  // 			bottomBorder.classList.add('active')
  // 		}
  // 		if (window.scrollY < slideContentBlock.getBoundingClientRect().height) {
  // 			bottomBorder.classList.remove('active')
  // 		}

  // 	})
  // }
};
