import { render, staticRenderFns } from "./slider-index2.vue?vue&type=template&id=74d6de44&"
import script from "./slider-index2.vue?vue&type=script&lang=js&"
export * from "./slider-index2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderIndexSlide2: require('/home/d/didisher2/automart-cars.ru/www/components/slider-index/slider-index-slide2.vue').default})
